import Head from 'next/head';
import { Fragment } from 'react';

const HeadTag = ({ metaObj }: any) => {
  const { title, metas } = metaObj;

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet="UTF-8"></meta>
        <meta httpEquiv="X-UA-compatible" content="IE=edge"></meta>
        {metas.map((meta: any, i: number) => (
          <Fragment key={i}>
            <meta name={meta.name} content={meta.content}></meta>
          </Fragment>
        ))}
        <link rel="icon" href="/favicon.ico"></link>
      </Head>
    </>
  );
};

export default HeadTag;
