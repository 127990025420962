import HeadTag from '@components/core/head-tag';

interface ILayout {
  children: any;
  metaObj: any;
}

const Layout = ({ children, metaObj }: ILayout) => {
  return (
    <>
      <HeadTag metaObj={metaObj} />
      <main className="w-full max-w-[1600px] h-full p-[0px] my-0 mx-auto overflow-x-hidden bg-white">
        {children}
      </main>
    </>
  );
};

export default Layout;
